import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/global/Layout"
import { Heading, Body } from "../components/typography"
import { media } from "../constants"

const StyledBlock = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
`
const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 3em;
  grid-auto-flow: row;
  margin-bottom: 2em;
`
const StyledResponseWrapper = styled.div`
  height: max-content;
  margin: auto;
  h2 {
    margin-top: 1em;
    margin-bottom: 0.4em;
  }
  p {
    margin-bottom: 2em;
  }
  @media ${media.large} {
    grid-area: 1/1/3/2;
  }
`
const signupSuccess = ({ data }) => {
  const content = data.prismic.allLayouts.edges[0].node

  return (
    <Layout
      data={content}
      pageTitle="tilmeld"
      showPageTitle={false}
      showPageShortDescription={false}
      pageShortDescription=""
      announcement={null}
    >
      <StyledBlock>
        <StyledWrapper>
          <StyledResponseWrapper>
            <Heading size="h2" align="center">
              Tak for din tilmelding
            </Heading>
            <Body size="big" align="center">
              Vi kontakter dig hurtigst muligt
            </Body>
          </StyledResponseWrapper>
        </StyledWrapper>
      </StyledBlock>
    </Layout>
  )
}

export const query = graphql`
  query layoutQueryAndLayoutQuery {
    prismic {
      allLayouts {
        edges {
          node {
            prismic_title
            _meta {
              lang
              type
              uid
              alternateLanguages {
                uid
                type
                lang
              }
            }
            cta_theme
            cta_label
            cta_page_link {
              ... on PRISMIC_Page {
                path
              }
            }
            footer_links_left {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            footer_links_middle {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            footer_links_right {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            navigation_links_right {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            navigation_links_middle {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            navigation_links_left {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            socials {
              icon
              label
              link_url {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default signupSuccess
